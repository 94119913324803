import ApiService from '@/services/ApiService';

const API_URL = '/api/Customer/Messages/';

class CustomerMessageService {
  delete(id: string) {
    return ApiService.delete(`${API_URL}Delete?id=${id}`);
  }

  Create(message: { subject: string; body: string }, receiverId: string) {
    return ApiService.post(`${API_URL}Create`, {
      ...message,
      receiverId: receiverId,
    });
  }

  forCreate(receiverId: string) {
    return ApiService.get(`${API_URL}Create?receiverId=${receiverId}`);
  }

  getDetail(messageId: string) {
    return ApiService.get(`${API_URL}Detail?messageId=${messageId}`);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new CustomerMessageService();
