






























import Vue from 'vue';
import CustomerMessageService from '@/services/customers/CustomerMessageService';
import { minLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import ToastMessage from '@/models/ToastMessage';
import { MetaInfo } from 'vue-meta';

class MessageViewModel {
  subject = '';
  body = '';
}

const validations = {
  message: {
    subject: {
      required,
      minLength: minLength(5),
    },
    body: {
      required,
      minLength: minLength(5),
    },
  },
};

export default Vue.extend({
  mixins: [validationMixin],
  validations: validations,
  components: {
    ValidatedInput,
    ValidatedTextArea,
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.guest.GuestCreateMessage.meta.title').toString(),
    };
  },
  props: {
    receiverId: {
      required: true,
      type: String,
    },
    orgSubject: {
      default: undefined,
      type: String,
    },
  },
  data() {
    return {
      data: {},
      ready: false,
      message: new MessageViewModel(),
      errorMessage: '',
    };
  },
  mounted() {
    this.loadData();
    if (this.orgSubject != undefined) {
      this.message.subject = 'Re: ' + this.orgSubject;
    }
  },
  methods: {
    loadData() {
      CustomerMessageService.forCreate(this.receiverId).then((resp) => {
        this.data = resp.value;
        this.ready = true;
        this.setBreadCrumb();
      });
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.guest.GuestCreateMessage.breadcrumb.last' },
      ]);
    },

    handle() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorMessage = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        CustomerMessageService.Create(this.message, this.receiverId).then(
          () => {
            this.$store.commit('addToastMessage', new ToastMessage('Erfolgreich gesendet', 'bg-success'));
            this.$router.push({
              name: 'CustomerMessageIndex',
            });
          },
          (error) => {
            this.$store.commit('addToastMessage', new ToastMessage('Senden fehlgeschlagen', 'bg-warning'));
            this.errorMessage = error;
          }
        );
      }
    },
  },
});
